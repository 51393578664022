<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Company Detail</strong>
      </CCardHeader>
      <CCardBody>
        <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
          {{ err_msg }}
        </CAlert>
        <div class="detail-div">
          <CRow>
            <CCol md="3">
              <CRow>
                <CCol md="12" class="text-left">
                  <img alt="Profile Pic" :src="profileImage" height="auto" width="100%"/>
                </CCol>
              </CRow>
            </CCol>
            <CCol md="9" class="align-self-center">
              <CRow>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.company }}</p>
                  <p class="custome-detail">{{ profile.company }}</p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.companyType }}</p>
                  <p class="custome-detail">{{ profile.companyType }}</p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.status }}</p>
                  <p class="custome-detail">
                    <CBadge :color="statusBudget(profile.status)">{{ profile.status }}</CBadge>
                  </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.address1 }}</p>
                  <p class="custome-detail">{{ profile.address1 }}</p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.country }}</p>
                  <p class="custome-detail">{{ profile.country }}</p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.state }}</p>
                  <p class="custome-detail">{{ profile.state }}</p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.city }}</p>
                  <p class="custome-detail">{{ profile.city }}</p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">{{ $lang.profile.form.website }}</p>
                  <p class="custome-detail">{{ profile.website }}</p>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
        <hr>
        <h2>Leave Users</h2>
        <v-server-table :columns="columns" :url="url" :options="options" ref="myTable"
                        @loaded="exports(self,'Excel',module,'DeletedStaffs')">
          <template #status="data">
            <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
          </template>
          <template #avatar="data">
            <Avatar :image=data.row.avatar :defaultImage=data.row.avatar :content=data.row.name :length="20"
                    :isProfileListPage="true"/>
          </template>
          <template #name="data">
            <span :title="data.row.name">{{ trimfunction(data.row.name, 20) }}</span>
          </template>
          <template #title="data">
            <span :title="data.row.title">{{ trimfunction(data.row.title, 20) }}</span>
          </template>
          <template #leaveTime="data">
            {{ dateTimeUTCtoLocalFormat(data.row.leaveTime) }}
          </template>
          <template #actions="data">
            <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.sent_mail"
                     v-if="data.row.zipPath != null && data.row.zipPath != ''" v-on:click="sentMailRow(data.row.id,data.row.zipPath)">
              <i class="fas fa-envelope"></i>
            </CButton>
          </template>
        </v-server-table>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {profile} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import Avatar from "../../components/Avatar";
import {ServerTable} from 'vue-tables-2';

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(ServerTable, {}, false)
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "CompanyView",
  mixins: [Mixin],
  components: {
    Avatar
  },
  data() {
    return {
      userId: "",
      messageColor: "",
      iAmUser: "",
      iAmCompany: "",
      removeForUser: true,
      numberOfUsers: "",
      submitted: false,
      Disabled: true,
      err_msg: "",
      profileImage: "",
      avatar: "",
      dismissCountDown: 0,
      self: this,
      module: profile,
      profile: {
        name: "",
        title: "",
        company: "",
        subIndustry: "",
        companyType: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        industry: "",
        user: "",
        status: "",
        skills: "",
        userId: "",
        email: "",
      },
      url: '',
      columns: ['avatar', 'role', 'leaveTime', 'actions'],
      data: [],
      options: {
        headings: {
          company: this.$lang.user_deleted.table.user_name,
          country: this.$lang.user_deleted.table.role,
          leaveTime: this.$lang.user_deleted.table.leaveTime,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ['avatar', 'role', 'leaveTime'],
        sortable: ['avatar', 'role', 'leaveTime'],
        filterable: ['avatar', 'role', 'leaveTime']
      },
    };
  },
  created() {
    let self = this;
    const id = this.$route.params.id;
    self.url = '/staff/list-staff-deleted/' + id;
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.editId = this.$route.params.id;
    axios.post('users/company/deleted/details/' + self.editId)
        .then((response) => {
          if (response.data) {
            let responseData = response.data.data;
            self.profile.website = responseData.website;
            self.profile.company = responseData.name;
            self.profile.address1 = responseData.address1;
            self.profileImage = responseData.image;
            self.profile.state = responseData.stateName;
            self.profile.city = responseData.cityName;
            self.profile.country = responseData.countryName;
            self.profile.status = responseData.verifiedStatus;
            self.profile.companyType = responseData.companyType;
            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    sentMailRow(id, path) {
      let self = this;
      axios.post('staff/sent/backup/mail', {id: id, path: path})
          .then((response) => {
            if (response.data) {
              self.err_msg = response.data.message;
              self.messageColor = "success";
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {
            store.commit("showLoader", false); // Loader Off
          });
    }
  },
};
</script>
